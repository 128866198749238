import {Helmet} from "react-helmet-async";
import {Box, Card, CardHeader, Container, Divider, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBack, RemoveDone} from "@mui/icons-material";
import {DriverRequestDetail} from "../sections/app/drivers";

export default function RequestDetailPage() {
  const navigate = useNavigate();
  const { requestId } = useParams()

  return (
    <>
      <Helmet>
        <title> Detail d'une demande | Taria </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Detail de la demande
          </Typography>
        </Stack>

        <Card>
          <CardHeader
            title={
              <Stack direction="row" spacing={2} >
                <Tooltip arrow placement="left" title="Retourner">
                  <IconButton color="secondary" onClick={() => navigate('/app/driver-requests')}>
                    <ArrowBack />
                  </IconButton>
                </Tooltip>
                <Typography>Details de la demande</Typography>
              </Stack>
            }
          />

          <Box sx={{ mx: 3, my: 2 }} dir="ltr">
            <DriverRequestDetail idRequest={requestId} />
          </Box>
        </Card>

      </Container>
    </>
  )
}