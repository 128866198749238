import axios from "axios";
import API_URL from "../../utils/apiUrl";

const createDriver = async (driverData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.post(`${API_URL}drivers/create`, driverData, config)
  console.log(response.data)
  return response.data
}

const getDriversByZone = async (searchData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.get(`${API_URL}get-drivers?long=${searchData.long}&lat=${searchData.lat}&city=${searchData.city}`, config)
  return response.data
}

const getDrivers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.get(`${API_URL}drivers`, config)
  return response.data
}

const getDriverById = async (id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios.get(`${API_URL}drivers/find?id=${id}`, config)
    return response.data
  } catch (error) {
    return error;
  }
}

const updateDriver = async (id, driverData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.post(`${API_URL}drivers/update?id=${id}`, driverData, config)
  console.log(response.data)
  return response.data
}

const changeDriverStatus = async (id, driverData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.post(`${API_URL}drivers/change-status?id=${id}`, driverData, config)
  console.log(response.data)
  return response.data
}

const deleteDriver = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.post(`${API_URL}drivers/delete?id=${id}`, config)
  console.log(response.data)
  return response.data
}


const getDriverRequests = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.get(`${API_URL}driver-request`, config)
  return response.data
}

const getDriverRequestById = async (id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    const response = await axios.get(`${API_URL}driver-requests/find?id=${id}`, config)
    return response.data
  } catch (error) {
    return error;
  }
}

const acceptOrRejectRequest = async (requestData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.post(`${API_URL}accpet-or-reject-request`, requestData, config)
  console.log(response.data)
  return response.data
}

const driverServices = {
  createDriver,
  getDriversByZone,
  getDrivers,
  getDriverById,
  updateDriver,
  changeDriverStatus,
  deleteDriver,
  getDriverRequests,
  getDriverRequestById,
  acceptOrRejectRequest
}

export default driverServices