import {Box, Button, Grid, LinearProgress} from "@mui/material";
import {Form, Formik} from "formik";
import {Event, Mail, Person, PhoneAndroid, Place} from "@mui/icons-material";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import moment from "moment";
import CustomTextInput from "../../../components/inputs/CustomTextInput";
import {updateProfile} from "../../../features/auth/authSlice";
import AppLoader from "../../../components/AppLoader";

const ProfileUpdateForm = () => {
    const dispatch = useDispatch();
    const {user, isLoading } = useSelector((state) => state.auth);

    const handleSubmit = (values, { setSubmitting }) => {
        const userData = {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            phone_number: values.phoneNumber,
            birth_date: values.birthDate,
            birth_place: values.birthPlace,
        }
        dispatch(updateProfile(userData)).then(async (response) => {
            setSubmitting(false);
            console.log(response.payload);
            if (response.payload.state) {
                toast.success(response.payload.message);
            } else {
              toast.error(response.payload);
            }
        });
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Formik
                    initialValues={{
                        firstName: user?.user.first_name,
                        lastName: user?.user.last_name,
                        email: user?.user.email,
                        phoneNumber: user?.user.phone_number,
                        birthDate: moment.utc(user?.user.birth_date).format('YYYY-MM-DD'),
                        birthPlace: user?.user.birth_place,
                    }}
                    validationSchema={Yup.object({
                        firstName: Yup.string()
                            .max(15, "Must be 15 characters or less")
                            .required("Veuillez saisir le nom"),
                        lastName: Yup.string()
                            .max(20, "Must be 20 characters or less")
                            .required("Veuillez saisir le prenom"),
                        email: Yup.string()
                            .email("Invalid email addresss`")
                            .required("Veuillez saisir l'email"),
                        phoneNumber: Yup.string()
                            // .phoneNumber('Must be a valid phone number')
                            .max(20, "Must be 20 characters or less")
                            .required('Veuillez saisir le numero de Télephone'),
                        birthDate: Yup.date()
                            .required('Veuillez entrer la date de naissance'),
                        birthPlace: Yup.string()
                            .max(20, "Must be 20 characters or less")
                            .required('Veuillez saisir le lieu de naissance'),
                    })}
                    onSubmit={handleSubmit}
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form>
                            <Grid container rowSpacing={0.25} columnSpacing={2} columns={2}>
                                <Grid item sm={2} md={1}>
                                    <CustomTextInput fullWidth Icon={<Person />} type="text" name="firstName" placeholder="Prenom" />
                                </Grid>
                                <Grid item sm={2} md={1}>
                                    <CustomTextInput fullWidth Icon={<Person />} type="text" name="lastName" placeholder="Nom" />
                                </Grid>
                                <Grid item sm={2} md={1}>
                                    <CustomTextInput fullWidth Icon={<Mail />} type="email" name="email" placeholder="Email" />
                                </Grid>
                                <Grid item sm={2} md={1}>
                                    <CustomTextInput fullWidth Icon={<PhoneAndroid />} type="tel" name="phoneNumber" placeholder="Numero de Telephone" />
                                </Grid>
                                <Grid item sm={2} md={1}>
                                    <CustomTextInput fullWidth Icon={<Event />} type="date" name="birthDate" placeholder="Date de Naissance" />
                                </Grid>
                                <Grid item sm={2} md={1}>
                                    <CustomTextInput fullWidth Icon={<Place />} type="text" name="birthPlace" placeholder="Lieu de Naissance" />
                                </Grid>
                            </Grid>
                            <br />
                            {isSubmitting && <LinearProgress />}
                            <br />
                            <div style={{ 'display': 'flex', 'justify-content': 'right', 'margin-right': '5px' }}>
                              <Button type="submit" disabled={isSubmitting} onClick={submitForm}>Sauvegarder</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
            <AppLoader open={isLoading} />
        </>
    );
}

export default ProfileUpdateForm
