import { useField } from "formik";
import styled from "@emotion/styled";
import {InputAdornment, TextField} from "@mui/material";

// Styled components ....
export const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'black',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'var(--tabHeadColor)',
            borderRadius: '5px',
            marginRight: '5px',
            marginLeft: '5px',
        },
        '&:hover fieldset': {
            borderColor: 'var(--tabHeadColor)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'var(--tabHeadColor)',
        },
    },
});

const inputProps = {}

// eslint-disable-next-line react/prop-types
const CustomTextInput = ({ Icon, inputProps, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <>
            <StyledTextField
                // variant="outlined"
                margin="normal"
                // fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment sx={{ }} position="start">
                            {Icon}
                        </InputAdornment>
                    ),
                    ...inputProps
                }}

                error={Boolean(meta.touched && meta.error)}
                helperText={meta.touched && meta.error}
                {...field} {...props}
            />
        </>
    );
};

export default CustomTextInput