// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Tableau de bord',
    path: '/app/dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Demandes conducteurs',
    path: '/app/driver-requests',
    icon: icon('ic_user'),
  },
  {
    title: 'Conducteurs',
    path: '/app/drivers',
    icon: icon('ic_user'),
  },
  {
    title: 'Trajets effectués',
    path: '/app/journeys',
    icon: icon('ic_user'),
  },
];

export default navConfig;
