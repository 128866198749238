import {Paper} from "@mui/material";
import {styled} from "@mui/material/styles";

export const StyledPaper = styled(Paper)(({ theme, color}) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: theme.palette[color].light,
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.common.white,
    borderRadius: 10,
    width: 110,
}));

// eslint-disable-next-line react/prop-types
const Status = ({status}) => (
    <StyledPaper variant="contained" square color={status ? 'success' : 'error'}>
        {status ? 'ACCEPTER' : 'REFUSER'}
    </StyledPaper>
)

export default Status