import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const driverJourneys = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  journey_date: faker.date.recent(),
  journey_status: sample([
    'EN COURS',
    'EN ATTENTE',
    'ACCEPTER',
    'REJETER',
  ]),
  payment_status: sample([
    'EN COURS',
    'EN ATTENTE',
    'ACCEPTER',
    'REJETER',
  ]),
  nbr_km: faker.random.numeric(),
  price: faker.random.numeric(5),
  depart: {
    city_name: faker.address.city(),
    lat: faker.address.latitude(9.64, 9.60),
    long: faker.address.longitude(-13.57, -13.61),
  },
  arrive: {
    city_name: faker.address.city(),
    lat: faker.address.latitude(9.64, 9.60),
    long: faker.address.longitude(-13.57, -13.61),
  },
  user: {
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    address: faker.address.city(),
    phoneNumber: faker.phone.number(),
    countryCode: faker.address.countryCode(),
    indicatif: '+221',
    email: faker.internet.email(),
    status: faker.datatype.boolean(),
  }
}));

export default driverJourneys;
