import React, {useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";

// MRT
import MaterialReactTable from 'material-react-table';
// eslint-disable-next-line camelcase
import {MRT_Localization_FR as Localization } from "material-react-table/locales/fr";

// @mui
import {frFR} from "@mui/material/locale";
import {Box, createTheme, IconButton, ThemeProvider, Tooltip, useTheme} from "@mui/material";

// Icons
import {Visibility} from "@mui/icons-material";
import {getDrivers} from "../../../features/drivers/driversSlice";

import DRIVERS from '../../../_mock/driver'


export default function DriverList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { drivers, isLoading, isError, message } = useSelector((state) => state.drivers);

  useEffect(() => {
    dispatch(getDrivers())
  }, [isError, message, dispatch])

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: 'Prenoms et Nom',
      },
      {
        accessorFn: (row) => `${row.indicatif} ${row.phoneNumber}`,
        header: 'Numero de telephone',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'address',
        header: 'Adresse',
      },
    ],
    [],
  );

  const theme = useTheme();

  /* eslint-disable-next-line camelcase */
  return (
    <Box sx={{ width: '100%' }}>
      <ThemeProvider theme={createTheme(theme, frFR)}>
        <MaterialReactTable
          columns={columns}
          data={DRIVERS}
          muiToolbarAlertBannerProps={
            isError
              ? {
                color: 'error',
                children: message ?? 'Error loading data',
              }
              : undefined
          }
          renderTopToolbarCustomActions={() => (
            <h4>Liste des chauffeurs</h4>
          )}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              // header: 'Change Account Settings', //change header text
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '0.1rem', alignContent: "center", alignItems: 'center' }}>
              <Tooltip arrow placement="left" title="Voir le detail">
                <IconButton color="info" onClick={() => navigate(`/app/drivers/${row.original.id}/detail/`)}>
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          state={{ isLoading }}
          localization={Localization}
        />
      </ThemeProvider>
    </Box>
  );
}
