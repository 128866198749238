import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// eslint-disable-next-line react/prop-types
const AppLoader = ({open}) => (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            // onClick={handleClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )

export default AppLoader