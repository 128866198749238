import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';
import {DriverList} from "../sections/app/drivers";
// components

// ----------------------------------------------------------------------

export default function DriverPage() {

  return (
    <>
      <Helmet>
        <title> User | Taria back office </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Liste des conducteurs
          </Typography>
        </Stack>

        <Card>
          <DriverList />
        </Card>
      </Container>
    </>
  );
}
