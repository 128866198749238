import React, { useState } from "react";
import GoogleMapReact from 'google-map-react';
import {Card, CardHeader, TextField} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {usePlacesWidget} from "react-google-autocomplete";
import { getDriversByZone } from "../../../features/drivers/driversSlice";


const AnyReactComponent = ({ text }) => (
  <div style={{
    color: 'white',
    background: 'grey',
    padding: '15px 10px',
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)'
  }}>
    {text}
  </div>
);

const Motorbike = () => (<img src="/assets/illustrations/motorbike.png" alt="login" height={30} />)

export default function DriversMap() {
  const [defaultProps, setDefaultProps] = useState({
    center: {
      address: 'Dakar',
      lat: 14.7645,
      lng: -17.366
    },
    zoom: 12
  });
  const { drivers, isLoading, isError, message } = useSelector((state) => state.drivers);

  const dispatch = useDispatch();

  const [country, setCountry] = useState("sn");

  const { ref: materialRef } = usePlacesWidget({
    apiKey: process.env.GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      if (place) {
        setDefaultProps({
          center: {
            address: place.formatted_address,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          },
          zoom: 12
        });
        console.log(defaultProps);

        dispatch(getDriversByZone({
          long: place.geometry.location.lng(),
          lat: place.geometry.location.lat(),
          city: place.formatted_address
        }));
      }
    },
    /*
    inputAutocompleteValue: "country",
    options: {
      componentRestrictions: { country },
    },
    */
  });

  return (
    <Card>
      <CardHeader
        action={
          <div style={{ width: "250px", margin: "5px 5px" }}>
            <TextField
              fullWidth
              color="secondary"
              variant="standard"
              placeholder="Recherchez une adresse..."
              inputRef={materialRef}
            />
          </div>
        }
        title='Carte google map'
      />
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          {(drivers?.nearestDriver ?? []).map((driver, index) => (
            <Motorbike
              key={index}
              lat={driver.geometry.coordinates[1]}
              lng={driver.geometry.coordinates[0]}
            />
          ))}
        </GoogleMapReact>
      </div>
    </Card>
  );
}