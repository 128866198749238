import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import {Container, Typography, Box} from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.customShadows.card,
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '45%',
  padding: '35px 65px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '35px 65px',
  // padding: theme.spacing(12, 0),
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Login | Taria back office </title>
      </Helmet>

      <Box
          sx={{
            height: '100vh',
            paddingX: mdUp ? 8 : 0,
            paddingY: mdUp ? 4 : 2,
            backgroundColor: '#80cbc4',
          }}
      >
        <StyledRoot>
          <Logo
              sx={{
                position: 'fixed',
                top: { xs: 16, sm: 24, md: 60 },
                left: { xs: 16, sm: 24, md: 120 },
              }}
          />

          {mdUp && (
              <StyledSection>
                <img src="/assets/illustrations/illustration_login.png" alt="login" />
              </StyledSection>
          )}

          <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h3" gutterBottom>
                Welcome Back
              </Typography>

              <Typography variant="body2" sx={{ mb: 5 }}>
                Login to continue
              </Typography>

              <LoginForm />
            </StyledContent>
          </Container>
        </StyledRoot>
      </Box>
    </>
  );
}
