import axios from 'axios'
import API_URL from "../../utils/apiUrl";

// Register user
const register = async (userData) => {
  const response = await axios.post(`${API_URL}sing-up`, userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }

  return response.data
}

// Login user
const login = async (userData) => {
  const response = await axios.post(`${API_URL}sign-in`, userData)

  if (response.data.user.role[0].libelle === 'ADMIN') {
    localStorage.setItem('user', JSON.stringify(response.data))
  } else {
    throw new Error("Vous n'avez pas les permissions d'accès.");
  }

  return response.data
}

// Logout user
const logout = () => {
  localStorage.removeItem('user')
}

// Update user profile
const updateProfile = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.post(`${API_URL}update-profile`, userData, config)
  return response.data
}

// Change user password
const changePassword = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.post(`${API_URL}change-password`, userData, config)
  return response.data
}

const auth = {
  register,
  logout,
  login,
  updateProfile,
  changePassword
}

export default auth