import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography } from '@mui/material';
import {PeopleOutlined} from "@mui/icons-material";
import {useState} from "react";
import { DriversMap, EarningCard } from '../sections/app/dashboard';
import TotalLightCard from "../components/cards/statistics/TotalLightCard";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const gridSpacing = 3;
  const [isLoading, setLoading] = useState(false);

  return (
    <>
      <Helmet>
        <title> Dashboard | Taria back office </title>
      </Helmet>
      <Container maxWidth="xl" sx={{ padding: '0px', margin: '0px' }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Tableau de bord
        </Typography>

        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid container spacing={gridSpacing}>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <EarningCard isLoading={isLoading} />
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item sm={6} xs={12} md={6} lg={12}>
                          <TotalLightCard title="Tot Conducteurs SN" total={50} icon={<PeopleOutlined />} color='primary' isLoading={isLoading} />
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={12}>
                          <TotalLightCard title="Tot Conduct. disponibles SN" total={30} icon={<PeopleOutlined />} color='error' isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item sm={6} xs={12} md={6} lg={12}>
                          <TotalLightCard title="Tot Conducteurs N" total={20} icon={<PeopleOutlined />} color='primary' isLoading={isLoading} />
                        </Grid>
                        <Grid item sm={6} xs={12} md={6} lg={12}>
                          <TotalLightCard title="Tot Conduct. disponibles GN" total={30} icon={<PeopleOutlined />} color='error' isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <DriversMap />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
