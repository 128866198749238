// eslint-disable-next-line import/named
import {StyledPaper} from "./Status";

const statusColors = {
    'PENDING': 'primary',
    'ACCEPTED': 'success',
    'REJECTED': 'error'
};

// eslint-disable-next-line react/prop-types
const RequestStatus = ({status}) => (
    <StyledPaper variant="contained" square color={statusColors[status] ?? 'primary'}>{status}</StyledPaper>
)

export default RequestStatus