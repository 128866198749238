import React, {useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";

// MRT
import MaterialReactTable from 'material-react-table';
// eslint-disable-next-line camelcase
import {MRT_Localization_FR as Localization } from "material-react-table/locales/fr";

// @mui
import {frFR} from "@mui/material/locale";
import {Box, createTheme, IconButton, ThemeProvider, Tooltip, useTheme} from "@mui/material";

// Icons
import {Visibility} from "@mui/icons-material";

import RequestStatus from "../../../components/RequestStatus";
import {getDriverRequests} from "../../../features/drivers/driverRequestsSlice";

const requestStatus = ['EN ATTENTE', 'EN COURS', 'ACCEPTER', 'REJETER'];

export default function DriverRequestList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { requests, isLoading, isError, message } = useSelector(state => state.driverRequests);

  useEffect(() => {
    dispatch(getDriverRequests())
  }, [isError, message, dispatch])

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.user[0].firstName} ${row.user[0].lastName}`,
        header: 'Prenoms et Nom',
      },
      {
        accessorFn: (row) => `${row.user[0].indicatif} ${row.user[0].phoneNumber}`,
        header: 'Numero de telephone',
      },
      {
        accessorKey: 'licence',
        header: 'N° Licence conducteur',
      },
      {
        accessorKey: 'identuty',
        header: "N° Carte d'identié",
      },
      {
        accessorFn: (row) => <RequestStatus status={row.status} />,
        header: 'Status',
        filterVariant: 'select',
        filterSelectOptions: requestStatus,
      },
    ],
    [],
  );

  const theme = useTheme();

  /* eslint-disable-next-line camelcase */
  return (
    <Box sx={{ width: '100%' }}>
      <ThemeProvider theme={createTheme(theme, frFR)}>
        <MaterialReactTable
          columns={columns}
          data={requests}
          muiToolbarAlertBannerProps={
            isError
              ? {
                color: 'error',
                children: message ?? 'Error loading data',
              }
              : undefined
          }
          renderTopToolbarCustomActions={() => (
            <h4>Liste des demandes</h4>
          )}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              // header: 'Change Account Settings', //change header text
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '0.1rem', alignContent: "center", alignItems: 'center' }}>
              <Tooltip arrow placement="left" title="Voir le detail">
                <IconButton color="info" onClick={() => navigate(`/app/driver-requests/${row.original._id}`)}>
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          state={{ isLoading }}
          localization={Localization}
        />
      </ThemeProvider>
    </Box>
  );
}
