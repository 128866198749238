import {Grid, Paper, Stack, Tooltip, Typography} from "@mui/material";
import { styled } from '@mui/material/styles';
import {DoneOutline, RemoveDone} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useState} from "react";
import {LoadingButton} from "@mui/lab";
import RequestStatus from "../../../components/RequestStatus";
// eslint-disable-next-line import/named
import {acceptOrRejectRequest} from "../../../features/drivers/driverRequestsSlice";

const IMG_BASE_URL ='https://b-hitech-bucket-dev.s3.amazonaws.com/';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  height: '300px',
  width: '100%',
});

// eslint-disable-next-line react/prop-types
export default function DriverRequestDetail({idRequest}) {
  const [isLoading, setLoading] = useState(false);
  const [request, setRequest] = useState(useSelector(state =>
    state.driverRequests.requests.find(request => request._id === idRequest)
  ));
  const dispatch = useDispatch();

  const handleAcceptOrRejectRequest = (action) => {
    const requestData = { idRequest, action };
    setLoading(true);

    dispatch(acceptOrRejectRequest(requestData)).then(async (response) => {
      setRequest({...request, ...response.payload.message});
      toast.success(response.payload.message);
      setLoading(false);
    }).catch(response => {
      toast.error(response.payload.message);
      setLoading(false);
    });
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowSpacing="5"
      columnSpacing="2"
    >
      <Grid item xs={4}>
        <Typography variant="body1" color="text.secondary">
          Conducteur: {`${request?.user[0].firstName} ${request?.user[0].lastName}`}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Item>
          <Typography variant="subtitle1" component="span">
            <RequestStatus status={request?.status} />
          </Typography>
        </Item>
      </Grid>

      <Grid item xs={4}>
        <Stack direction="row" spacing={2}>
          <Tooltip arrow placement="left" title="Accepter">
            <LoadingButton color="success" onClick={() => handleAcceptOrRejectRequest( 'ACCEPT')} loading={isLoading}>
              <DoneOutline />
            </LoadingButton>
          </Tooltip>
          <Tooltip arrow placement="left" title="Rejeter">
            <LoadingButton color="error" onClick={() => handleAcceptOrRejectRequest( 'REJECT')} loading={isLoading}>
              <RemoveDone />
            </LoadingButton>
          </Tooltip>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4">
          Carte d'identite nationale
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Numero: {request?.identuty}
        </Typography>
        <Stack direction="row" spacing={2} sx={{marginBottom:2}}>
          <Img alt="" src={IMG_BASE_URL+request?.imgIdentutyRecto} />
          <Img alt="" src={IMG_BASE_URL+request?.imgIdentutyVerso} />
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4">
          Licence de conducteur
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Numero: {request?.licence}
        </Typography>
        <Stack direction="row" spacing={2} sx={{marginBottom:2}}>
          <Img alt="" src={IMG_BASE_URL+request?.imgLicenceRecto} />
          <Img alt="" src={IMG_BASE_URL+request?.imgLicenceVerso} />
        </Stack>
      </Grid>
    </Grid>
  )
}