import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import driversReducer from '../features/drivers/driversSlice'
import driverRequestsReducer from '../features/drivers/driverRequestsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    drivers: driversReducer,
    driverRequests: driverRequestsReducer,
  },
})
