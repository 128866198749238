import { useState } from "react";
import { useField } from "formik";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff, Lock } from "@mui/icons-material";
import {StyledTextField} from "./CustomTextInput";

const CustomPasswordInput = ({ label, Icon, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <StyledTextField
                variant="outlined"
                margin="normal"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Lock />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                type={showPassword ? 'text' : 'password'}
                error={Boolean(meta.touched && meta.error)}
                helperText={meta.touched && meta.error}
                {...field} {...props}
            />
        </>
    );
};

export default CustomPasswordInput