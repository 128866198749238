import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';
// eslint-disable-next-line import/named
import {DriverRequestList} from "../sections/app/drivers";
import DriverJourneyList from "../sections/app/drivers/DriverJourneyList";
// components

// ----------------------------------------------------------------------

export default function DriverJourneyPage() {

  return (
    <>
      <Helmet>
        <title> Trajets | Taria </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Trajets effectués
          </Typography>
        </Stack>

        <Card>
          <DriverJourneyList />
        </Card>
      </Container>
    </>
  );
}
