import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

// @mui
import { TextField, Checkbox, Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {Lock, Person, Visibility, VisibilityOff} from "@mui/icons-material";

// third party
import * as Yup from 'yup';
import {Formik, Form} from "formik";
// import {TextField, Checkbox} from 'formik-mui'

// components
import {login, reset} from "../../../features/auth/authSlice";
// import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess || user) {
      navigate('/app/dashboard')
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(login(values))
    setSubmitting(false)
  }

  return (
    <>
      <Formik
        initialValues={{
          phoneNumber: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          phoneNumber: Yup.string().max(255).required("Le numero est obligatoire"),
          password: Yup.string().max(255).required('Le mot de passe est obligatoire')
        })}
        onSubmit={handleSubmit}
        /* onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setStatus({ success: false });
            setSubmitting(false);
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }} */
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <Form>
            <Stack spacing={3}>
              <TextField
                type="text"
                value={values.phoneNumber}
                name="phoneNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Numéro de téléphone"
                InputProps={{startAdornment: <Person />}}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
              />

              <TextField
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Mot de passe"
                InputProps={{
                  startAdornment: <Lock />,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {/* <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} /> */}
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <Checkbox name="remember" label="Remember me" />
              <Link variant="subtitle2" underline="hover">
                Mot de passe oublié?
              </Link>
            </Stack>
            <Stack direction="col" justifyContent="center" sx={{ my: 2 }}>
              <LoadingButton size="large" variant="contained" sx={{width:180}}
                             loading={isLoading} onClick={handleSubmit}>
                Se connecter
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
}
