import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const RequireAuth = ({children}) => {
  // const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  if (!user) {
    // return navigate('/')
    return <Navigate to='/' />;
  }

  return children || <Outlet />;
}

export default RequireAuth