import {Box, Grid, LinearProgress} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import CustomPasswordInput from "../../../components/inputs/CustomPasswordInput";
import {changePassword} from "../../../features/auth/authSlice";
import Button from "../../../theme/overrides/Button";


const PasswordUpdateForm = () => {
    const dispatch = useDispatch();

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        const userData = {
            old_password: values.oldPassword,
            new_password: values.newPassword,
        }
        dispatch(changePassword(userData)).then(async (response) => {
            setSubmitting(false);
            resetForm();

            console.log(response.payload);
            if (response.payload.state) {
              toast.success(response.payload.message);
            } else {
              toast.error(response.payload);
            }
        });
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Formik
                initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                }}
                validationSchema={Yup.object({
                    oldPassword: Yup.string()
                        .required("Veuillez saisir l'ancien mot de passe"),
                    newPassword: Yup.string()
                        .max(20, "Must be 20 characters or less")
                        .required('Veuillez saisir le mot de passe'),
                        // .matches(
                        //	/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                        //	"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                        // ),
                    confirmPassword: Yup.string()
                        .required('Veuillez confirmer le mot de passe')
                        .oneOf([Yup.ref('newPassword')], 'Passwords does not match'),
                        // .test('passwords-match', 'Passwords must match', function(value){
                        //	return this.parent.password === value
                        // }),
                })}
                onSubmit={handleSubmit}
            >
                {({ submitForm, isSubmitting }) => (
                    <Form>
                        <Grid container rowSpacing={0.25} columnSpacing={2} columns={2}>
                            <Grid item sm={2} md={1}>
                                <CustomPasswordInput fullWidth name="oldPassword" placeholder="Ancien mot de passe" />
                            </Grid>
                            <Grid item sm={2} md={1}>
                                <CustomPasswordInput fullWidth name="newPassword" placeholder="Nouveau mot de passe" />
                            </Grid>
                            <Grid item sm={2} md={1}>
                                <CustomPasswordInput fullWidth name="confirmPassword" placeholder="Confirmation du mot de passe" />
                            </Grid>
                        </Grid>
                        <br />
                        {isSubmitting && <LinearProgress />}
                        <br />
                        <div style={{ 'display': 'flex', 'justify-content': 'right', 'margin-right': '5px' }}>
                            <Button type="submit" disabled={isSubmitting} onClick={submitForm}>Sauvegarder</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}

export default PasswordUpdateForm
