// ----------------------------------------------------------------------

export default function Paper() {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          /*
          backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          ...theme.typography.body2,
          padding: theme.spacing(1),
          textAlign: 'center',
          color: theme.palette.text.secondary,
           */

          // color: 'var(--primaryColor1) !important',
          // fontFamily: "'Lato', sans-serif !important",
        },
      },
    },
    /* MuiSelected: {
      color: 'var(--withCol) !important',
      backgroundColor: 'var(--primaryColor1) !important',
      borderTopLeftRadius: '20px !important',
      borderTopRightRadius: '20px !important',
    } */
  };
}
