import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import driverService from "./driver";

const initialState = {
  requests: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: ''
}

export const getDriverRequests = createAsyncThunk('driver-requests', async (_, thunkAPI) => {
  try {
    const {token} = JSON.parse(localStorage.getItem('user'))
    return await driverService.getDriverRequests(token)
  } catch (error) {
    const message = (error.response && error.response.data  && error.response.data.message)
      || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const acceptOrRejectRequest = createAsyncThunk('driver-requests/accept-or-reject', async (requestData, thunkAPI) => {
  try {
    const {token} = JSON.parse(localStorage.getItem('user'))
    return await driverService.acceptOrRejectRequest(requestData, token)
  } catch (error) {
    const message = (error.response && error.response.data  && error.response.data.message)
      || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
});

export const deleteDriver = createAsyncThunk('driver-requests/delete', async (id, thunkAPI) => {
  try {
    // const token = thunkAPI.getState().auth.user.token
    const {token} = JSON.parse(localStorage.getItem('user'))
    return await driverService.deleteDriver(id, token)
  } catch (error) {
    const message = (error.response && error.response.data  && error.response.data.message)
      || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
});

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDriverRequests.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDriverRequests.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.requests = action.payload.driverRequest
        state.message = action.payload.message
      })
      .addCase(getDriverRequests.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(acceptOrRejectRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(acceptOrRejectRequest.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // state.requests.push(action.payload)
      })
      .addCase(acceptOrRejectRequest.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteDriver.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteDriver.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.requests = state.requests.filter(request => request._id !== action.payload._id)
      })
      .addCase(deleteDriver.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  }
});

export const {reset} = requestSlice.actions

export default requestSlice.reducer