import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import RequireAuth from "./components/RequireAuth";
// pages
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import ProfilePage from "./pages/ProfilePage";
import DriverPage from "./pages/DriverPage";
import DriverRequestPage from "./pages/DriverRequestPage";
import RequestDetailPage from "./pages/RequestDetailPage";
import DriverJourneyPage from "./pages/DriverJourneyPage";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <LoginPage/>,
    },
    {
      path: '/app',
      element: <RequireAuth children={<DashboardLayout/>} />,
      children: [
        // { element: <Navigate to="/app/dashboard" />, index: true },
        {path: 'dashboard', element: <DashboardAppPage />},
        {path: 'user', element: <UserPage/>},
        {path: 'profile', element: <ProfilePage/>},
        {path: 'driver-requests', element: <DriverRequestPage />},
        {path: 'driver-requests/:requestId', element: <RequestDetailPage />},
        {path: 'drivers', element: <DriverPage />},
        {path: 'journeys', element: <DriverJourneyPage />},
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/app/dashboard" />, index: true },
        {path: '404', element: <Page404 />},
        {path: '*', element: <Navigate to="/404" />},
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
