import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const drivers = [...Array(10)].map((_, index) => ({
  id: faker.datatype.uuid(),
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  address: faker.address.city(),
  phoneNumber: faker.phone.number(),
  countryCode: faker.address.countryCode(),
  indicatif: '+221',
  email: faker.internet.email(),
  status: faker.datatype.boolean(),
  role: sample([
    'CUSTOMER',
    'DRIVER',
    'ADMIN',
  ]),
  position: {
    city_name: faker.address.city(),
    lat: faker.address.latitude(9.64, 9.60),
    long: faker.address.longitude(-13.57, -13.61),
  }
}));

export default drivers;
