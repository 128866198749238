import React, {useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";

// MRT
import MaterialReactTable from 'material-react-table';
// eslint-disable-next-line camelcase
import {MRT_Localization_FR as Localization } from "material-react-table/locales/fr";

// @mui
import {frFR} from "@mui/material/locale";
import {Box, createTheme, IconButton, ThemeProvider, Tooltip, useTheme} from "@mui/material";

// Icons
import {Visibility} from "@mui/icons-material";

import JOURNEYS from '../../../_mock/driverJourney'
import RequestStatus from "../../../components/RequestStatus";
import {getDriverRequests} from "../../../features/drivers/driverRequestsSlice";

const requestStatus = ['EN ATTENTE', 'EN COURS', 'ACCEPTER', 'REJETER'];

export default function DriverJourneyList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { requests, isLoading, isError, message } = useSelector((state) => state.driverRequests);

  useEffect(() => {
    dispatch(getDriverRequests())
  }, [isError, message, dispatch])

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.user.firstName} ${row.user.lastName}`,
        header: 'Conducteur',
      },
      {
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
        accessorFn: (row) => new Date(row.journey_date),
        accessorKey: 'journey_date',
        header: 'Date du trajet',
        muiTableHeadCellFilterTextFieldProps: {
          type: 'date',
        },
        sortingFn: 'datetime',
      },
      {
        accessorFn: (row) => `${row.nbr_km} Km`,
        header: 'Distance',
      },
      {
        accessorKey: 'depart.city_name',
        header: 'Depart',
      },
      {
        accessorKey: 'arrive.city_name',
        header: 'Arrivé',
      },
      {
        accessorFn: (row) => `${row.price} GNF`,
        header: 'Price',
      },
      {
        accessorFn: (row) => <RequestStatus status={row.journey_status} />,
        header: 'Status',
        filterVariant: 'select',
        filterSelectOptions: requestStatus,
      },
    ],
    [],
  );

  const theme = useTheme();

  /* eslint-disable-next-line camelcase */
  return (
    <Box sx={{ width: '100%' }}>
      <ThemeProvider theme={createTheme(theme, frFR)}>
        <MaterialReactTable
          columns={columns}
          data={requests?.requests ?? JOURNEYS}
          muiToolbarAlertBannerProps={
            isError
              ? {
                color: 'error',
                children: message ?? 'Error loading data',
              }
              : undefined
          }
          renderTopToolbarCustomActions={() => (
            <h4>Liste des trajets</h4>
          )}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              // header: 'Change Account Settings', //change header text
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '0.1rem', alignContent: "center", alignItems: 'center' }}>
              <Tooltip arrow placement="left" title="Voir le detail">
                <IconButton color="info" onClick={() => navigate(`/app/driver-requests/${row.original.id}/detail/`)}>
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          state={{ isLoading }}
          localization={Localization}
        />
      </ThemeProvider>
    </Box>
  );
}
