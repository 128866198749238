import axios from "axios";
import API_URL from "../../utils/apiUrl";


const getBalance = async (from) => {
  const {token} = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const response = await axios.get(`${API_URL}get-balance?target=${from}`, config)
  return response.data
}


const statisticsServices = {
  getBalance,
}

export default statisticsServices