import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import driverService from "./driver";

const initialState = {
  drivers: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: ''
}

export const getDrivers = createAsyncThunk('drivers', async (_, thunkAPI) => {
  try {
    const {token} = JSON.parse(localStorage.getItem('user'))
    return await driverService.getDrivers(token)
  } catch (error) {
    const message = (error.response && error.response.data  && error.response.data.message)
        || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const getDriversByZone = createAsyncThunk('get-drivers', async (searchData, thunkAPI) => {
  try {
    const {token} = JSON.parse(localStorage.getItem('user'))
    return await driverService.getDriversByZone(searchData, token)
  } catch (error) {
    const message = (error.response && error.response.data  && error.response.data.message)
      || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const createDriver = createAsyncThunk('drivers/create', async (driverData, thunkAPI) => {
  try {
    const {token} = JSON.parse(localStorage.getItem('user'))
    return await driverService.createDriver(driverData, token)
  } catch (error) {
    const message = (error.response && error.response.data  && error.response.data.message)
      || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
});

export const updateDriver = createAsyncThunk('drivers/update', async (driverData, thunkAPI) => {
  try {
    const {token} = JSON.parse(localStorage.getItem('user'))
    return await driverService.updateDriver(driverData.id, driverData, token)
  } catch (error) {
    const message = (error.response && error.response.data  && error.response.data.message)
      || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
});

export const changeDriverStatus = createAsyncThunk('drivers/change-status', async (driverData, thunkAPI) => {
  try {
    const {token} = JSON.parse(localStorage.getItem('user'))
    return await driverService.changeDriverStatus(driverData.id, driverData, token)
  } catch (error) {
    const message = (error.response && error.response.data  && error.response.data.message)
      || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
});

export const deleteDriver = createAsyncThunk('drivers/delete', async (id, thunkAPI) => {
  try {
    // const token = thunkAPI.getState().auth.user.token
    const {token} = JSON.parse(localStorage.getItem('user'))
    return await driverService.deleteDriver(id, token)
  } catch (error) {
    const message = (error.response && error.response.data  && error.response.data.message)
      || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
});

const driversSlice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDriver.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createDriver.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // state.drivers.push(action.payload)
      })
      .addCase(createDriver.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getDrivers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.drivers = action.payload
      })
      .addCase(getDrivers.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getDriversByZone.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDriversByZone.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.drivers = action.payload
      })
      .addCase(getDriversByZone.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateDriver.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateDriver.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // state.drivers.push(action.payload)
      })
      .addCase(updateDriver.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(changeDriverStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(changeDriverStatus.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // state.drivers.push(action.payload)
      })
      .addCase(changeDriverStatus.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteDriver.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteDriver.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // state.drivers = state.drivers.filter(
        //    (drivers) => drivers.id !== action.payload.id
        // )
      })
      .addCase(deleteDriver.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  }
});

export const {reset} = driversSlice.actions

export default driversSlice.reducer