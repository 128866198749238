import {useState} from "react";
import {Box, Card, Container, Stack, Typography} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Lock, Person} from "@mui/icons-material";
import {Helmet} from "react-helmet-async";
import TabPanel, {a11yProps} from "../components/tabs/TabPanel";
import ProfileUpdateForm from "../sections/app/profile/ProfileUpdateForm";
import PasswordUpdateForm from "../sections/app/profile/PasswordUpdateForm";


const ProfilePage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title> Mon profil | Taria back office </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Mon profil
          </Typography>
        </Stack>

        <Card>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab icon={<Person />} iconPosition="start"
                     label="Modifier les infos du profil" {...a11yProps(0)} />
                <Tab icon={<Lock />} iconPosition="start"
                     label="Modifier le mot de passe" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <ProfileUpdateForm />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PasswordUpdateForm />
            </TabPanel>
          </Box>
        </Card>
      </Container>
    </>
  );
}

export default ProfilePage
